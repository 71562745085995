import React, {useEffect} from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {Skeleton} from "@mui/material";
import {formatCurrency} from "../../utils/textUtils";

const CustomTooltip = ({active, payload, label}) => {
    // Borrowed from https://stackoverflow.com/questions/68617980/recharts-treemap-tooltip-label
    const tooltipStyle = {
        background: '#262626', color: 'white', padding: '5px', opacity: 0.9
    }
    if (active && payload && payload.length) {
        return (<div style={tooltipStyle}>
            <div style={{fontWeight: 'bold'}}>{`${payload[0].payload.name}`}</div>
            <div style={{textAlign: 'right', margin: '5px 0'}}>
                {`${formatCurrency(payload[0].value)}`}
            </div>
        </div>);
    }
    return null;
};

const SourcesPie = ({data = [], isServerResponded}) => {

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ea6aff', '#6b72ff'];

    const formatAmountsForPieChart = () => {
        const formattedData = [];
        data?.forEach(item => {
            formattedData.push({
                name: item.source_name, value: item.last_entry_amount
            })
        })
        return formattedData;
    }

    const dataToUse = formatAmountsForPieChart();

    if (!data || !isServerResponded) return <Skeleton className={'skeleton-loader'} animation="wave" variant="rectangular" height={'100%'} width={'100%'}/>;

    return (<div style={{height: '240px'}}>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
                <Pie label={false} stroke={'none'} isAnimationActive={true} paddingAngle={5} data={dataToUse}
                     dataKey="value" nameKey="name" innerRadius={70} outerRadius={100}>
                    {dataToUse?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
                </Pie>
                <Tooltip content={<CustomTooltip/>}/>
            </PieChart>
        </ResponsiveContainer>
    </div>);
}

export default SourcesPie;

