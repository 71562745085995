import React, {useContext, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from '@mui/material';
import {ApplicationContext} from '../../context/ApplicationContext';
import {submitCreateSource} from '../../services/source.service';
import {AddOutlined} from '@material-ui/icons';

const CreateSourceDialog = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [open, setOpen] = React.useState(false);
    const [sourceName, setSourceName] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        submitCreateSource({
            source_type_key: props.sourceTypeKey, source_name: sourceName
        })
            .then((reply) => {
                AppContext.handleNotification('Source created successfully.');
                props.updateLastFetched();
                handleClose();
            })
            .catch((err) => {
                AppContext.handleError('Unable to create source.');
            })
    }

    return (<div>
        <IconButton
            onClick={handleClickOpen}
            style={{backgroundColor: 'white', color: 'black', float: 'right', padding: '12px'}}
        >
            <AddOutlined/>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className='modal primary-font'>Create Source</DialogTitle>
            <DialogContent className='modal primary-font'>
                <DialogContentText className='modal primary-font'>
                    Provide the source name, then click Submit to create the new source. To go back, click Cancel.
                </DialogContentText>
                <TextField
                    className='modal primary-font'
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Source Name"
                    type="email"
                    fullWidth
                    variant="standard"
                    onInput={(e) => setSourceName(e.target.value)}
                />
            </DialogContent>
            <DialogActions className='modal primary-font'>
                <Button className='modal primary-font' onClick={handleClose}>Cancel</Button>
                <Button className='modal primary-font' onClick={submitForm}>Submit</Button>
            </DialogActions>
        </Dialog>
    </div>);
}

export default CreateSourceDialog;
