import React, {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from '../context/ApplicationContext';
import {Grid} from '@material-ui/core';
import CreateSource from '../components/CRUDModals/CreateSource';
import CardSource from '../components/DataCards/CardSource';
import CardSourceSkeleton from '../components/DataCards/CardSourceSkeleton';
import SourcesPie from "../components/Charts/SourcesPie";
import {getSourceMany} from '../services/source.service';

const SourcesPage = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [lastFetched, setLastFetched] = useState(new Date());
    const [sources, setSources] = useState(null);
    const [isServerResponded, setIsServerResponded] = useState(false);

    useEffect(async () => {
        setIsServerResponded(false);
        window.scrollTo(0, 0);
        try {
            const loadedSources = await getSourceMany(props.sourceTypeKey);
            setSources(loadedSources);
        } catch (e) {
            AppContext.handleError('Unable to load sources.');
        }
        setIsServerResponded(true);
    }, [props, lastFetched]);

    const generateSourceCards = () => {
        let sourceCards = [];
        if (!isServerResponded) {
            for (let i = 0; i < 2; i++) {
                sourceCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <CardSourceSkeleton/>
                </Grid>);
            }
        } else if (sources?.length) {
            for (let i = 0; i < sources.length; i++) {
                sourceCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={sources[i].source_id}>
                    <CardSource data={sources[i]} colorModulus={i}/>
                </Grid>);
            }
        } else {
            sourceCards.push(<Grid item xs={12}>
                <div style={{textAlign: 'center', marginTop: '2rem'}}>
                    No sources to show for this source type.
                    Create a new source to see it appear here.
                </div>
            </Grid>);
        }
        return sourceCards;
    }

    const updateLastFetched = () => setLastFetched(new Date());

    return (<div className='animated fadeIn primary-font'>
        <br/>
        <section>
            <Grid container spacing={2} style={{minHeight: '240px'}}>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <SourcesPie data={sources} isServerResponded={isServerResponded}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <span className='large-label primary-font'>{props.sourceTypeName}</span>
                        <br/>
                        <p className='small-label primary-font'>To create a new source, click the
                            button below.</p>
                        <br/>
                        <div style={{
                            minHeight: '150px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'
                        }}>
                            <CreateSource sourceTypeKey={props.sourceTypeKey}
                                          updateLastFetched={updateLastFetched}/>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
        <div style={{textAlign: 'right', margin: '1rem 0'}}>
        </div>
        <Grid container spacing={1}>
            {generateSourceCards()}
        </Grid>
    </div>);

};

export default SourcesPage;
