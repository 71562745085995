import React, {useContext, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from '@mui/material';
import {EditOutlined} from '@material-ui/icons';
import {ApplicationContext} from '../../context/ApplicationContext';
import {submitUpdateSource} from '../../services/source.service';

const UpdateSourceDialog = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [open, setOpen] = React.useState(false);
    const [sourceName, setSourceName] = useState(props.sourceName);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        submitUpdateSource({
            original_key: props.sourceKey,
            source_name: sourceName,
            source_type_key: props.sourceTypeKey
        })
            .then((reply) => {
                AppContext.handleNotification('Source updated successfully.');
                handleClose();
            })
            .catch((err) => {
                AppContext.handleError('Unable to update source.');
            })
    }

    return (
        <div>
            <IconButton
                onClick={handleClickOpen}
                style={{backgroundColor: 'white', color: 'black', float: 'right', padding: '12px', marginLeft: '1rem'}}
            >
                <EditOutlined/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className='modal primary-font'>Edit Source</DialogTitle>
                <DialogContent className='modal primary-font'>
                    <DialogContentText className='modal primary-font'>
                        Provide the source name, then click Submit to update the source. To go back, click Cancel.
                    </DialogContentText>
                    <TextField
                        className='modal primary-font'
                        autoFocus
                        value={sourceName}
                        margin="dense"
                        id="name"
                        label="Source Name"
                        type="email"
                        fullWidth
                        variant="standard"
                        onInput={(e) => setSourceName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions className='modal primary-font'>
                    <Button className='modal primary-font' onClick={handleClose}>Cancel</Button>
                    <Button className='modal primary-font' onClick={submitForm}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdateSourceDialog;
