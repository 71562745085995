import client from './graphql/_client';
import {
    getInstantAmounts as getInstantAmountsQuery,
    getMonthlyReportMany as getMonthlyReportManyQuery
} from './graphql/monthlyReport';
import {simulateDelay} from '../utils/requestUtils';

export const getMonthlyReportMany = async (sortObj) => {
    await simulateDelay();
    const {data} = await client.query({
        query: getMonthlyReportManyQuery,
        variables: {
            sortObj: {
                column: sortObj.column,
                direction: sortObj.direction
            }
        },
        fetchPolicy: 'no-cache'
    });
    return data.getMonthlyReportMany;
}

export const getInstantAmounts = async () => {
    try {
        await simulateDelay();
        const data = await client.query({
            query: getInstantAmountsQuery,
            // variables: {
            //     sortObj: {
            //         column: sortObj.column,
            //         direction: sortObj.direction
            //     }
            // },
            fetchPolicy: 'no-cache'
        });
        return data;
    } catch (e) {
        console.log(e);
    }

}
