import React, {useContext, useEffect, useState} from 'react';
import {ApplicationContext} from '../context/ApplicationContext';
import {Grid} from '@material-ui/core';
import {Skeleton} from '@mui/material';
import SourceTypesTreemap from "../components/Charts/SourceTypesTreemap";
import PieChartFinal from "../components/Charts/SourceTypesPie";
import {formatCurrency} from '../utils/textUtils';
import {getSourceTypeMany} from '../services/sourceType.service';
import {getInstantAmounts, getMonthlyReportMany} from '../services/monthlyReport.service';
import {getSourceMany} from '../services/source.service';

const InstantAnalyticsPage = () => {
    const AppContext = useContext(ApplicationContext);
    const [sources, setSources] = useState([]);
    const [liquidAssetsValue, setLiquidAssetsValue] = useState(null);
    const [frozenAssetsValue, setFrozenAssetsValue] = useState(null);
    const [instantAmounts, setInstantAmounts] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [isAwaitingServer, setIsAwaitingServer] = useState(false);

    useEffect(async () => {
        setIsAwaitingServer(true);
        window.scrollTo(0, 0);
        try {
            const [loadedSources, loadedSourceTypes, loadedMonthlyReports, instantAmounts] = await Promise.all([getSourceMany(), getSourceTypeMany(), getMonthlyReportMany({
                column: 'year_and_month', direction: -1
            }), getInstantAmounts()]);
            formatDataForLiquidity(loadedSourceTypes);
            setLastUpdated(loadedMonthlyReports && loadedMonthlyReports[0] && loadedMonthlyReports[0].report_date ? loadedMonthlyReports[0].report_date : null)
            setSources(loadedSources);
            setInstantAmounts(instantAmounts);
        } catch (e) {
            AppContext.handleError('Unable to load data.');
        }
        setIsAwaitingServer(false);
    }, []);

    const formatDataForLiquidity = (rawData) => {
        let liquidAssets = 0;
        let frozenAssets = 0;
        rawData.forEach((item) => {
            if (item.key === "BANK ACCOUNT") {
                liquidAssets += item.current_value;
            } else if (item.key !== "LIABILITY") {
                frozenAssets += item.current_value;
            }
        });
        setLiquidAssetsValue(liquidAssets);
        setFrozenAssetsValue(frozenAssets);
    };

    const liquidityRatio = (liquidAssetsValue / (liquidAssetsValue + frozenAssetsValue) * 100).toFixed(1);

    const populateLiquidityCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{liquidityRatio}%</span>}

            <br/>
            <p className='small-label primary-font'>Your current liquidity, the ratio of your liquid assets to your total
                assets.</p>
        </div>);
    }

    const populateActiveSourcesCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{sources.length}</span>}
            <br/>
            <p className='small-label primary-font'>The total of number of active sources across which your assets and
                liabilities are spread.</p>
        </div>);
    }

    const populateLiquidAssetsAmountCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{formatCurrency(processInstantAmounts().liquidAssets)}</span>}
            <br/>
            <p className='small-label primary-font'>The total combined value of your liquid assets.</p>
        </div>);
    }

    const populateFrozenAssetsAmountCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{formatCurrency(processInstantAmounts().frozenAssets)}</span>}
            <br/>
            <p className='small-label primary-font'>The total combined value of your frozen assets.</p>

        </div>);
    }

    const populateLiabilitiesAmountCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{formatCurrency(processInstantAmounts().liabilities)}</span>}
            <br/>
            <p className='small-label primary-font'>The total combined value of your liabilities.</p>

        </div>);
    }

    const populateNetWorthAmountCard = () => {
        return (<div className={'card-bg'} style={{height: '100%', padding: '1rem', textAlign: 'center'}}>
            {isAwaitingServer ? <Skeleton className={'skeleton-loader'} style={{height: '30px'}}/> :
                <span className='large-label primary-font'>{formatCurrency(processInstantAmounts().netWorth)}</span>}
            <br/>
            <p className='small-label primary-font'>Your current net worth as determined by adding liquid assets to frozen
                assets and subtracting liabilities.</p>
        </div>);
    }

    const processInstantAmounts = () => {
        const insAmounts = instantAmounts?.data?.getInstantAmountsBySourceType || [];

        const liquidAssets = insAmounts?.filter(item => item?.source_type_name === 'Bank Account')[0]?.current_value || 0
        const calculateFrozenAssets = () => {
            let frozenAssetsSum = 0;
            insAmounts?.forEach(item => {
                if (item?.source_type_name !== 'Bank Account' && item?.source_type_name !== 'Liability') {
                    frozenAssetsSum += item?.current_value
                }
            });
            return frozenAssetsSum;
        }
        const liabilities = insAmounts?.filter(item => item?.source_type_name === 'Liability')[0]?.current_value || 0
        const netWorth = liquidAssets + calculateFrozenAssets() - liabilities;
        return {
            liquidAssets, frozenAssets: calculateFrozenAssets(), liabilities, netWorth
        }
    }

    return (<div className='animated fadeIn'>
        <section>
            <Grid container spacing={2} style={{minHeight: '400px'}}>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <PieChartFinal instantAmounts={instantAmounts?.data}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <SourceTypesTreemap instantAmounts={instantAmounts?.data}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                            <span className='large-label primary-font'>Understand your finances.</span>
                        <br/>
                        <p className='small-label primary-font'>
                            Gain a better understanding of how your wealth is distributed, and identify trends in
                            your net worth. Discover your current liquidity, and view your average monthly
                            gain/loss.
                        </p>
                    </div>
                </Grid>
            </Grid>
        </section>
        <br/>
        <section>
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>{populateNetWorthAmountCard()}</Grid>
                <Grid item xs={12} md={3}>{populateLiquidityCard()}</Grid>
                <Grid item xs={12} md={3}>{populateLiquidAssetsAmountCard()}</Grid>
                <Grid item xs={12} md={3}>{populateFrozenAssetsAmountCard()}</Grid>
                <Grid item xs={12} md={3}>{populateLiabilitiesAmountCard()}</Grid>
                <Grid item xs={12} md={3}>{populateActiveSourcesCard()}</Grid>
            </Grid>
        </section>
    </div>);

};


export default InstantAnalyticsPage;
