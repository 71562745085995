import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {CardContent, Typography} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const CardSourceSkeleton = (props) => {

    return (<NavLink to="#">
        <div className={'card-bg'} style={{textAlign: 'right', cursor: 'pointer', position: 'relative'}}>
            <CardContent>
                <Typography sx={{mb: 1.5}} style={{fontWeight: '700'}}>
                    <Skeleton className={'skeleton-loader'} style={{width: '100px', float: 'right'}}/>
                    <br/>
                </Typography>
                <Typography variant="header" color="text.secondary">
            <span style={{fontWeight: '800', fontSize: '2rem'}}>
              <Skeleton className={'skeleton-loader'} style={{width: '150px', float: 'right', height: '4rem'}}/>
              <br/>
            </span>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                        <div/>
                        <Skeleton className={'skeleton-loader'} style={{width: '120px', float: 'right'}}/>
                    </div>
                </Typography>
            </CardContent>
        </div>
    </NavLink>);
}

export default CardSourceSkeleton;
