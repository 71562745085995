import Login from "./pages/Login";
import LoggedOut from "./pages/LoggedOut";
import MonthlyReportsPage from "./pages/MonthlyReports";
import InstantAnalyticsPage from "./pages/InstantAnalytics";
import EntriesPage from "./pages/Entries";
import SourcesPage from "./pages/Sources";
import ThemePicker from "./components/Other/ThemePicker";
import packageJson from '../package.json';
import React from "react";

const instashellSetup = {
    applicationVersion: packageJson.version,
    unshelledRoutesArr: [
        {
            path: "/",
            targetPageComponent: (props) => <Login/>
        },
        {
            path: "/loggedOut",
            targetPageComponent: (props) => <LoggedOut/>
        }
    ],
    shelledRoutesArr: [
        {
            path: "/monthlyReports",
            pageTitle: 'Monthly Reports',
            hasBackButton: false,
            targetPageComponent: (props) => <MonthlyReportsPage/>
        },
        {
            path: "/instantAnalytics",
            pageTitle: 'Instant Analytics',
            hasBackButton: false,
            targetPageComponent: (props) => <InstantAnalyticsPage/>
        },
        {
            path: "/entries/:source_key/:source_name/:source_type_key",
            pageTitle: 'Entries',
            hasBackButton: true,
            targetPageComponent: (props) => <EntriesPage sourceKey={props.match.params.source_key}
                                                         sourceName={props.match.params.source_name}
                                                         sourceTypeKey={props.match.params.source_type_key}/>
        },
        {
            path: "/sources/:source_type_key/:source_type_name",
            pageTitle: 'Sources',
            hasBackButton: true,
            targetPageComponent: (props) => <SourcesPage sourceTypeKey={props.match.params.source_type_key}
                                                         sourceTypeName={props.match.params.source_type_name}/>
        }
    ],
    sidebarHeader: {
        titleLabel: 'Verdantime',
        titleFont: 'Cookie',
        titleSize: '40px'
    },
    sidebarItemsArr: [
        {type: 'link', label: 'Instant Analytics', path: '/instantAnalytics'},
        {type: 'link', label: 'Monthly Reports', path: '/monthlyReports'},
        {type: 'break'},
        {type: 'link', label: 'Bank Accounts', path: '/sources/BANK%20ACCOUNT/Bank%20Accounts'},
        {type: 'link', label: 'Currencies', path: '/sources/CURRENCY/Currencies'},
        {type: 'link', label: 'Real Estate', path: '/sources/REAL%20ESTATE/Real%20Estate'},
        {type: 'link', label: 'Receivables', path: '/sources/RECEIVABLE/Receivables'},
        {type: 'link', label: 'Stocks', path: '/sources/STOCK/Stocks'},
        {type: 'link', label: 'Vehicles', path: '/sources/VEHICLE/Vehicles'},
        {type: 'link', label: 'Liabilities', path: '/sources/LIABILITY/Liabilities'},
        {type: 'break'},
        {type: 'link', label: 'Logout', path: '/loggedOut'}
    ],
    footerItemsArr: [
        {
            title: "Modules",
            contents: [
                {type: 'link', label: 'Instant Analytics', path: '/instantAnalytics'},
                {type: 'link', label: 'Monthly Reports', path: '/monthlyReports'}
            ]
        },
        {
            title: "Source Types",
            contents: [
                {type: 'link', label: 'Bank Accounts', path: '/sources/BANK%20ACCOUNT/Bank%20Accounts'},
                {type: 'link', label: 'Currencies', path: '/sources/CURRENCY/Currencies'},
                {type: 'link', label: 'Real Estate', path: '/sources/REAL%20ESTATE/Real%20Estate'},
                {type: 'link', label: 'Receivables', path: '/sources/RECEIVABLE/Receivables'},
                {type: 'link', label: 'Stocks', path: '/sources/STOCK/Stocks'},
                {type: 'link', label: 'Vehicles', path: '/sources/VEHICLE/Vehicles'},
                {type: 'link', label: 'Liabilities', path: '/sources/LIABILITY/Liabilities'},
            ]
        },
        {
            title: "Theme",
            contents: [
                {type: 'component', component: <ThemePicker label={'Light'} value={'light'}/>},
                {type: 'component', component: <ThemePicker label={'Dark'} value={'dark'}/>},
                {type: 'component', component: <ThemePicker label={'Blue'} value={'blue'}/>},
                {type: 'component', component: <ThemePicker label={'Pink'} value={'pink'}/>},
            ]
        }
    ]
}

export default instashellSetup;
