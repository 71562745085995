import React, {useContext} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from '@mui/material';
import {ArchiveOutlined} from '@material-ui/icons';
import {ApplicationContext} from '../../context/ApplicationContext';
import {submitArchiveSource} from '../../services/source.service';
import _ from 'lodash';

const ArchiveSourceDialog = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        submitArchiveSource({
            source_key: props.sourceKey, is_archived: true
        })
            .then((reply) => {
                AppContext.handleNotification('Source archived successfully.');
                handleClose();
            })
            .catch((err) => {
                const isNotZero = _.find(err.graphQLErrors, {message: "Source is not at a balance of zero."});
                isNotZero ? AppContext.handleError('Source balance must be zero before it can be archived.') : AppContext.handleError('Unable to archive source.');
            })
    }

    return (<div>
        <IconButton
            onClick={handleClickOpen}
            style={{backgroundColor: 'white', color: 'black', float: 'right', padding: '12px', marginLeft: '1rem'}}
        >
            <ArchiveOutlined/>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className='modal primary-font'>Archive Source</DialogTitle>
            <DialogContent className='modal primary-font'>
                <DialogContentText className='modal primary-font'>
                    Archiving a source will remove it from view, but its entries will remain in order to calculate
                    historical wealth.
                    A source's current balance must be equal to zero before it can be archived.
                </DialogContentText>
            </DialogContent>
            <DialogActions className='modal primary-font'>
                <Button className='modal primary-font' onClick={handleClose}>Cancel</Button>
                <Button className='modal primary-font' onClick={submitForm}>Proceed</Button>
            </DialogActions>
        </Dialog>
    </div>);
}

export default ArchiveSourceDialog;
