import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Button} from '@material-ui/core';

const LoggedOutPage = () => {

    return (<div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh'
    }}>
        <div>
            <h3 className='primary-font' style={{
                textAlign: 'center', fontSize: '30px', margin: '0px'
            }}>
                Logout successful.
            </h3>
            <br/>
            <div style={{textAlign: 'center'}}>
                <NavLink to={'/'}>
                    <Button className='primary-font' variant='ghost' style={{margin: '5px'}}>
                        Return to login page
                    </Button>
                </NavLink>
            </div>
        </div>
    </div>);

};

export default LoggedOutPage;
