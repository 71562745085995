import React, {useContext, useState} from 'react';
import moment from 'moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from '@mui/material';
import {ApplicationContext} from '../../context/ApplicationContext';
import {AddOutlined, FlashOnOutlined} from '@material-ui/icons';
import {submitCreateEntry} from '../../services/entry.service';

const CreateEntryDialog = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [open, setOpen] = React.useState(false);
    const [entryDate, setEntryDate] = useState(moment().format('YYYY-MM-DD'));
    const [entryAmount, setEntryAmount] = useState('');
    const [entryComments, setEntryComments] = useState('');

    const handleClickOpen = () => {
        props.isRapidCreate && props.newestEntryDetails ? submitForm(null, props.newestEntryDetails.amount) : setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const submitForm = (e, rapidCreateAmount) => { // Where 'e' is event
        submitCreateEntry({
            source_key: props.sourceKey,
            entry_date: entryDate,
            amount: rapidCreateAmount || Number(entryAmount),
            comments: entryComments
        })
            .then((reply) => {
                AppContext.handleNotification('Entry created successfully.');
                props.updateLastFetched();
                handleClose();
            })
            .catch((err) => {
                AppContext.handleError('Unable to create entry.');
            })
    }

    return (<div>
        <IconButton
            onClick={handleClickOpen}
            style={{backgroundColor: 'white', color: 'black', float: 'right', padding: '12px', marginLeft: '1rem'}}
        >
            {props.isRapidCreate ? <FlashOnOutlined/> : <AddOutlined/>}
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className='modal primary-font'>Create Entry</DialogTitle>
            <DialogContent className='modal primary-font'>
                <DialogContentText className='modal primary-font'>
                    Provide an effective date and amount (with optional comments), then click Submit to create the
                    new entry.
                </DialogContentText>
                <TextField
                    className='modal primary-font'
                    autoFocus
                    margin="dense"
                    label="Effective Date"
                    type="date"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                    value={entryDate}
                    onInput={(e) => setEntryDate(e.target.value)}
                />
                <TextField
                    className='modal primary-font'
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={entryAmount}
                    onInput={(e) => setEntryAmount(e.target.value)}
                />
                <TextField
                    className='modal primary-font'
                    margin="dense"
                    label="Comments"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={entryComments}
                    onInput={(e) => setEntryComments(e.target.value)}
                />
            </DialogContent>
            <DialogActions className='modal primary-font'>
                <Button className='modal primary-font' onClick={handleClose}>Cancel</Button>
                <Button className='modal primary-font' onClick={submitForm}>Submit</Button>
            </DialogActions>
        </Dialog>
    </div>);
}

export default CreateEntryDialog;
