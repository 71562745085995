import client from './graphql/_client';
import {simulateDelay} from '../utils/requestUtils';
import {getSourceTypeMany as getSourceTypeManyQuery} from './graphql/sourceType';

export const getSourceTypeMany = async () => {
    await simulateDelay();
    const {data} = await client.query({
        query: getSourceTypeManyQuery, fetchPolicy: 'no-cache'
    });
    return data.getSourceTypeMany;
}
