import React, {useContext, useState} from 'react';
import moment from 'moment';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import {ApplicationContext} from '../../context/ApplicationContext';
import {submitUpdateEntry} from '../../services/entry.service';

const UpdateEntryDialog = (props) => {
    const AppContext = useContext(ApplicationContext);
    const [open, setOpen] = React.useState(false);
    const [entryDate, setEntryDate] = useState(props.entryDate);
    const [entryAmount, setEntryAmount] = useState(props.entryAmount);
    const [entryComments, setEntryComments] = useState(props.entryComments);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        submitUpdateEntry({
            _id: props.entryId, entry_date: entryDate, amount: Number(entryAmount), comments: entryComments
        })
            .then((reply) => {
                AppContext.handleNotification('Entry updated successfully.');
                props.updateLastFetched();
                handleClose();
            })
            .catch((err) => {
                AppContext.handleError('Unable to update entry.');
            })
    }

    return (<div className='medium-label primary-font' style={{display: 'inline', marginRight: '1rem', cursor: 'pointer'}}>
        <span onClick={handleClickOpen}>EDIT</span>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className='modal primary-font'>Update Entry</DialogTitle>
            <DialogContent className='modal primary-font'>
                <DialogContentText className='modal primary-font'>
                    Provide an effective date and amount (with optional comments), then click Submit to update the
                    entry.
                </DialogContentText>
                <TextField
                    className='modal primary-font'
                    autoFocus
                    margin="dense"
                    label="Effective Date"
                    type="date"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                    onInput={(e) => setEntryDate(e.target.value)}
                    value={moment(entryDate).format('YYYY-MM-DD')}
                />
                <TextField
                    className='modal primary-font'
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    onInput={(e) => setEntryAmount(e.target.value)}
                    value={entryAmount}
                />
                <TextField
                    className='modal primary-font'
                    margin="dense"
                    label="Comments"
                    type="text"
                    fullWidth
                    variant="standard"
                    onInput={(e) => setEntryComments(e.target.value)}
                    value={entryComments}
                />
            </DialogContent>
            <DialogActions>
                <Button className='modal primary-font' onClick={handleClose}>Cancel</Button>
                <Button className='modal primary-font' onClick={submitForm}>Submit</Button>
            </DialogActions>
        </Dialog>
    </div>);
}

export default UpdateEntryDialog;
