import React, {useContext} from 'react';
import {ApplicationContext} from '../../context/ApplicationContext';
import moment from 'moment';
import {Card, CardContent, Typography} from '@mui/material';
import {formatCurrency} from '../../utils/textUtils';
import UpdateEntry from '../CRUDModals/UpdateEntry';
import {submitDeleteEntry} from '../../services/entry.service';

const CardEntry = (props) => {
    const AppContext = useContext(ApplicationContext);

    const handleDeleteEntry = (entryId) => {
        if (confirm('Are you sure you want to delete this entry?')) {
            submitDeleteEntry(entryId)
                .then((reply) => {
                    props.updateLastFetched();
                    AppContext.handleNotification('Entry deleted successfully.');
                })
                .catch((err) => {
                    AppContext.handleError('Unable to delete entry.');
                });
        }
    }

    return (<Card className={'card-bg'} elevation={0} style={{minHeight: '230px'}}>
            <CardContent>
                <Typography className='secondary-font' sx={{mb: 1.5}} style={{fontWeight: '700'}}>
                    {moment(props.data.entry_date).format('LL')}
                </Typography>
                <Typography variant="header">
                    <span className={'primary-font'} style={{fontWeight: '800', fontSize: '2rem'}}>
                        {formatCurrency(props.data.amount).substring(0, formatCurrency(props.data.amount).length - 2)}
                        <sup className={'primary-font'} style={{
                            fontWeight: '800', fontSize: '1rem'
                        }}>{formatCurrency(props.data.amount).substring(formatCurrency(props.data.amount).length - 2, formatCurrency(props.data.amount).length)}</sup>
                    </span>
                    <br/>
                    <div className={'primary-font'} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                        {props.data.comments || 'No comments.'}
                    </div>
                </Typography>
                <div>
                    <span>
                        <UpdateEntry
                            entryId={props.data._id}
                            entryDate={props.data.entry_date}
                            entryAmount={props.data.amount}
                            entryComments={props.data.comments}
                            updateLastFetched={props.updateLastFetched}
                        />
                    </span>
                    <span className='medium-label primary-font' style={{cursor: 'pointer'}}
                          onClick={() => handleDeleteEntry(props.data._id)}>DELETE</span>
                </div>
            </CardContent>
        </Card>);
}

export default CardEntry;
