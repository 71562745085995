import moment from 'moment';

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value);
}

export const extractLastUpdatedDateFromSparkData = (reply) => {
    const lastUpdatedDate = reply && reply[0] ? moment(reply[0].report_date).format('LLL') : null;
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(lastUpdatedDate));
    const hoursSinceLastUpdate = duration.asHours().toFixed(0);
    return {lastUpdatedDate, hoursSinceLastUpdate};
}
