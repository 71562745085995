import gql from 'graphql-tag';

// FRAGMENTS

export const sourceFragment = gql`
    fragment Source on Source {
        source_name,
        key,
        last_entry_date,
        is_archived,
        last_entry_amount,
        source_type_key,
        username
    }
`;

// QUERIES & MUTATIONS

export const getSourceMany = gql`
    query getSourceMany($findObj: FindSourceInput) {
        getSourceMany(findObj: $findObj) { 
            ...Source
        }
    }
    ${sourceFragment}
`;

export const createSource = gql`
    mutation createSource($input: CreateSourceInput) {
        createSource(input: $input) { 
            ...Source
        }
    }
    ${sourceFragment}
`;

export const updateSource = gql`
    mutation updateSource($input: UpdateSourceInput) {
        updateSource(input: $input) { 
            nModified
        }
    }
`;

export const archiveSource = gql`
    mutation archiveSource($input: ArchiveSourceInput) {
        archiveSource(input: $input) { 
            nModified
        }
    }
`;
