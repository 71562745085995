import React from "react";
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import moment from 'moment';
import _ from 'lodash';
import {formatCurrency} from "../../utils/textUtils";
import {Skeleton} from "@mui/material";

const CustomTooltip = ({active, payload, label}) => {
    // Borrowed from https://stackoverflow.com/questions/68617980/recharts-treemap-tooltip-label
    const tooltipStyle = {
        background: '#262626', color: 'white', padding: '5px', opacity: 0.9
    }
    if (active && payload && payload.length) {
        return (<div style={tooltipStyle}>
            <div style={{fontWeight: 'bold'}}>{`${payload[0].payload.Date}`}</div>
            <div style={{textAlign: 'right', margin: '5px 0'}}>
                {`${formatCurrency(payload[0].value)}`}
            </div>
        </div>);
    }
    return null;
};

const EntryTimeline = ({chartData}) => {

    const formattedData = [];
    const sortedEntries = _.orderBy(chartData, 'entry_date', 'asc');
    sortedEntries?.forEach(item => {
        formattedData.push({
            Date: moment(item.entry_date).format("MMM 'YY"), Amount: item.amount
        })
    });

    if (!chartData?.length) return <Skeleton className={'skeleton-loader'} animation="wave" variant="rectangular" height={'100%'} width={'100%'}/>

    return (<ResponsiveContainer>
        <AreaChart data={formattedData} margin={{right: 0, left: 0, bottom: 0}}>
            <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="Date" hide={false} angle={0}/>
            <Tooltip content={<CustomTooltip/>}/>
            <Area type="monotone" dataKey="Amount" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)"/>
        </AreaChart>
    </ResponsiveContainer>);
};

export default EntryTimeline;
