import gql from 'graphql-tag';

// FRAGMENTS

export const sourceTypeFragment = gql`
    fragment SourceType on SourceType {
        key,
        label_plural,
        label_singular,
        is_asset,
        is_liquid,
        current_value,
        username
    }
`;

// QUERIES & MUTATIONS

export const getSourceTypeMany = gql`
    query getSourceTypesMany {
        getSourceTypeMany { 
            ...SourceType
        }
    }
    ${sourceTypeFragment}
`;