import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {ApplicationContext} from '../context/ApplicationContext';
import {Chip, Grid} from '@material-ui/core';
import CreateEntry from '../components/CRUDModals/CreateEntry';
import CardEntry from '../components/DataCards/CardEntry';
import UpdateSource from '../components/CRUDModals/UpdateSource';
import ArchiveSource from '../components/CRUDModals/ArchiveSource';
import CardEntrySkeleton from '../components/DataCards/CardEntrySkeleton';
import LineChart from "../components/Charts/EntryTimeline";
import {getEntryMany} from '../services/entry.service';

const EntriesPage = ({sourceKey, sourceName, sourceTypeKey}) => {
    const AppContext = useContext(ApplicationContext);
    const [lastFetched, setLastFetched] = useState(new Date());
    const [entries, setEntries] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [sortColumn, setSortColumn] = useState('entry_date');
    const [sortDirection, setSortDirection] = useState(-1);
    const [isRespondedServer, setIsRespondedServer] = useState(false);

    useEffect(async () => {
        setIsRespondedServer(false);
        window.scrollTo(0, 0);
        try {
            const loadedEntries = await getEntryMany(sourceKey, page, pageSize, sortColumn, sortDirection);
            setEntries(loadedEntries);
        } catch (e) {
            AppContext.handleError('Unable to load entries.');
        }
        setIsRespondedServer(true);
    }, [page, sortColumn, sortDirection, lastFetched]);

    const updateLastFetched = () => setLastFetched(new Date());

    const generateEntryCards = () => {
        let entryCards = [];
        if (!isRespondedServer) {
            for (let i = 0; i < 7; i++) {
                entryCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <CardEntrySkeleton/>
                </Grid>);
            }
        } else if (isRespondedServer && !entries.length) {
            return (<Grid item xs={12}>
                <div style={{textAlign: 'center', marginTop: '2rem'}}>
                    No entries to show for this source.
                    Create a new entry to see it appear here.
                </div>
                <br/>
            </Grid>);
        } else {
            for (let i = 0; i < entries.length; i++) {
                entries[i].entry_date = moment(entries[i].entry_date).utc();
                entryCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={entries[i]._id}>
                    <CardEntry data={entries[i]} updateLastFetched={updateLastFetched}/>
                </Grid>);
            }
        }
        return entryCards;
    }

    const goFirstPage = () => {
        setPage(0);
        window.scrollTo({top: 200, behavior: 'smooth'});
    }

    const goPreviousPage = () => {
        setPage(page - 1);
        window.scrollTo({top: 200, behavior: 'smooth'});
    }

    const goNextPage = () => {
        setPage(page + 1);
        window.scrollTo({top: 200, behavior: 'smooth'});
    }

    return (<div class='animated fadeIn'>
        <section>
            <Grid container spacing={2} style={{minHeight: '240px'}}>
                <Grid item xs={12} md={8}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <LineChart chartData={entries}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <span className='large-label primary-font'>{sourceName}</span>
                        <br/>
                        <p className='small-label primary-font'>To create a new entry, click the
                            add button below. To edit details about this source, click the edit button.</p>
                        <div style={{
                            minHeight: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <ArchiveSource
                                sourceKey={sourceKey}
                                sourceName={sourceName}
                                sourceTypeKey={sourceTypeKey}
                            />
                            <UpdateSource
                                sourceKey={sourceKey}
                                sourceName={sourceName}
                                sourceTypeKey={sourceTypeKey}
                            />
                            <CreateEntry
                                newestEntryDetails={entries && entries.length ? entries[0] : {}}
                                sourceKey={sourceKey}
                                updateLastFetched={updateLastFetched}
                            />
                            <CreateEntry
                                isRapidCreate={true}
                                newestEntryDetails={entries && entries.length ? entries[0] : {}}
                                disabled={!entries || !entries.length}
                                sourceKey={sourceKey}
                                updateLastFetched={updateLastFetched}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </section>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>

        </div>
        <br/>
        <Grid container spacing={1}>
            {generateEntryCards()}
        </Grid>
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
            {page !== 0 ?
                <Chip className='primary-font' variant="outlined" label="Return to First Page" style={{margin: '0.5rem'}}
                      onClick={(e) => goFirstPage()}>Return to First Page</Chip> :
                <Chip className='primary-font' variant="outlined" label="Return to First Page" style={{margin: '0.5rem'}}
                      disabled onClick={(e) => goFirstPage()}>Return to First Page</Chip>}
            {page > 0 ? <Chip className='primary-font' variant="outlined" label="Previous Page" style={{margin: '0.5rem'}}
                              onClick={(e) => goPreviousPage()}>Previous Page</Chip> :
                <Chip className='primary-font' variant="outlined" label="Previous Page" style={{margin: '0.5rem'}} disabled
                      onClick={(e) => goPreviousPage()}>Previous Page</Chip>}
            {entries.length === pageSize ?
                <Chip className='primary-font' variant="outlined" label="Next Page" style={{margin: '0.5rem'}}
                      onClick={(e) => goNextPage()}>Next Page</Chip> :
                <Chip className='primary-font' variant="outlined" label="Next Page" style={{margin: '0.5rem'}} disabled
                      onClick={(e) => goNextPage()}>Next Page</Chip>}
        </div>
    </div>);

};

export default EntriesPage;
