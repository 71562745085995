import React from 'react';
import {Area, Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import _ from "lodash";
import moment from "moment/moment";
import {Skeleton} from "@mui/material";
import {formatCurrency} from "../../utils/textUtils";

const CustomTooltip = ({active, payload, label}) => {
    // Borrowed from https://stackoverflow.com/questions/68617980/recharts-treemap-tooltip-label

    const tooltipStyle = {
        background: '#262626',
        color: 'white',
        padding: '5px',
        opacity: 0.9,
        width: '300px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    }

    if (active && payload && payload.length) {

        return (<div style={tooltipStyle}>
            <table>
                <tr>
                    <td>
                        <strong>{label}</strong>
                    </td>
                    <td/>
                </tr>
                <tr>
                    <td>
                        <hr/>
                    </td>
                    <td>
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>{payload[0].name}</td>
                    <td style={{textAlign: 'right'}}>{formatCurrency(payload[0].value)}</td>
                </tr>
                <tr>
                    <td>{payload[1].name}</td>
                    <td style={{textAlign: 'right'}}>{formatCurrency(payload[1].value)}</td>
                </tr>
                <tr>
                    <td>{payload[2].name}</td>
                    <td style={{textAlign: 'right'}}>{formatCurrency(payload[2].value)}</td>
                </tr>
                <tr>
                    <td>
                        <hr/>
                    </td>
                    <td>
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>{payload[3].name}</td>
                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{formatCurrency(payload[3].value)}</td>
                </tr>
            </table>
        </div>)

    }
    return null;
}


const MonthlyReportsTimeline = ({chartData}) => {

    const formattedData = [];
    const sortedReports = _.orderBy(chartData, 'year_and_month', 'asc');
    sortedReports?.forEach(item => {
        formattedData.push({
            Date: moment(item.year_and_month).format("MMM 'YY"),
            'Liquid Assets Total': item.liquid_assets_total,
            'Frozen Assets Total': item.frozen_assets_total,
            'Liabilities Total': item.liabilities_total,
            'Net Worth': item.net_worth
        });
    });

    if (!chartData) return <Skeleton className={'skeleton-loader'} animation="wave" variant="rectangular" height={'100%'} width={'100%'}/>

    return (<ResponsiveContainer>
        <ComposedChart
            width={500}
            height={400}
            isAnimationActive={true}
            data={formattedData}
            margin={{
                // top: 10,
                right: 0, left: 0, bottom: 0,
            }}
        >
            <XAxis dataKey="Date"/>
            <Tooltip content={<CustomTooltip/>}/>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor='#0088FE' stopOpacity={1}/>
                    <stop offset="95%" stopColor='#0088FE' stopOpacity={0.3}/>
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor='#00C49F' stopOpacity={1}/>
                    <stop offset="95%" stopColor='#00C49F' stopOpacity={0.3}/>
                </linearGradient>
                <linearGradient id="colorXv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor='#FF8042' stopOpacity={1}/>
                    <stop offset="95%" stopColor='#FF8042' stopOpacity={0.3}/>
                </linearGradient>
            </defs>
            <Area type="monotone" dataKey="Frozen Assets Total" stroke="none" fillOpacity={1} fill="url(#colorUv)"/>
            <Area type="monotone" dataKey="Liquid Assets Total" stroke="none" fillOpacity={1} fill="url(#colorPv)"/>
            <Bar name={"Liabilities Total"} dataKey="Liabilities Total" barSize={20} fill="url(#colorXv)"/>
            <Line type="monotone" dataKey="Net Worth" stackId="1" stroke='#FFBB28' fill="yellow"/>
        </ComposedChart>
    </ResponsiveContainer>);
}

export default MonthlyReportsTimeline;
