import * as React from 'react';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import {CardContent, Tooltip, Typography} from '@mui/material';
import {formatCurrency} from '../../utils/textUtils';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const generateOldDataWarning = (isCompletedEntryForCurrentDay) => {
    if (isCompletedEntryForCurrentDay) return <span/>;
    return (<div className='primary-font' style={{marginRight: '10px'}}>
        <Tooltip title={'Add a new to entry to ensure accurate equity analysis.'}>
            <ErrorOutlineOutlinedIcon className={'animated infinite flash slower'}/>
        </Tooltip>
    </div>);
}

const CardSource = (props) => {
    const linkUrl = `/entries/${props.data.key}/${props.data.source_name}/${props.data.source_type_key}`;
    const isCompletedEntryForCurrentDay = moment(props.data.last_entry_date).isSameOrAfter(moment().subtract(1, 'day'), 'day');
    const daysSinceLastUpdateLabel = props.data.last_entry_date !== (undefined || null) ? `${Math.abs(moment(props.data.last_entry_date).diff(moment(Date.now()), 'days'))} days ago` : 'No entries';

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ea6aff', '#6b72ff'];

    const colorModulusValue = props.colorModulus % COLORS.length
    const sourceColor = COLORS[colorModulusValue];

    return (<NavLink to={linkUrl}>
        <div className={'card-bg'} style={{
            textAlign: 'right',
            cursor: 'pointer',
            borderTop: '5px ' + sourceColor + ' solid',
        }}>
            <CardContent>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {generateOldDataWarning(isCompletedEntryForCurrentDay)}
                    <div className={'primary-font'} sx={{mb: 1.5}} style={{fontWeight: '700'}}>
                        {props.data.source_name}
                    </div>
                </div>
                <div>
                    <div className={'secondary-font'} style={{fontWeight: '800', fontSize: '2rem'}}>
                        {formatCurrency(props.data.last_entry_amount).substring(0, formatCurrency(props.data.last_entry_amount).length - 2)}
                        <sup style={{
                            fontWeight: '800', fontSize: '1rem'
                        }}>{formatCurrency(props.data.last_entry_amount).substring(formatCurrency(props.data.last_entry_amount).length - 2, formatCurrency(props.data.last_entry_amount).length)}</sup>
                    </div>
                    <br/>
                    <div className={'secondary-font'} style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem'}}>
                        <div>
                            {daysSinceLastUpdateLabel}
                        </div>
                    </div>
                </div>
            </CardContent>
        </div>
    </NavLink>);
}

export default CardSource;
