import client from './graphql/_client';
import {
    createEntry as createEntryMutation,
    deleteEntry as deleteEntryMutation,
    getEntryMany as getEntryManyQuery,
    updateEntry as updateEntryMutation
} from './graphql/entry';
import {simulateDelay} from '../utils/requestUtils';

export const getEntryMany = async (sourceKey, page, pageSize, sortColumn, sortDirection) => {
    await simulateDelay();
    const {data} = await client.query({
        query: getEntryManyQuery,
        variables: {
            findObj: {
                source_key: sourceKey
            },
            sortObj: {
                column: sortColumn,
                direction: sortDirection
            },
            skip: page * pageSize,
            limit: pageSize
        },
        fetchPolicy: 'no-cache'
    });
    return data.getEntryMany;
}

export const submitCreateEntry = async (input) => {
    await simulateDelay();
    const {data: {entry}} = await client.mutate({
        mutation: createEntryMutation,
        variables: {input}
    });
    return entry;
}

export const submitUpdateEntry = async (input) => {
    await simulateDelay();
    const {data: {entry}} = await client.mutate({
        mutation: updateEntryMutation,
        variables: {input}
    });
    return entry;
}

export const submitDeleteEntry = async (entryId) => {
    await simulateDelay();
    const {data: {entry}} = await client.mutate({
        mutation: deleteEntryMutation,
        variables: {_id: entryId}
    });
    return entry;
}
