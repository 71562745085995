import React from 'react';
import {Skeleton} from '@mui/material';
import {ResponsiveContainer, Tooltip, Treemap} from 'recharts';
import {formatCurrency} from "../../utils/textUtils";

const CustomTooltip = ({active, payload, label}) => {
    // Borrowed from https://stackoverflow.com/questions/68617980/recharts-treemap-tooltip-label
    const tooltipStyle = {
        background: '#262626', color: 'white', padding: '5px', opacity: 0.9
    }
    if (active && payload && payload.length) {
        return (<div style={tooltipStyle}>
            <div style={{fontSize: '10px'}}>{`${payload[0].payload.root.name}`}</div>
            <div style={{fontWeight: 'bold'}}>{`${payload[0].payload.name}`}</div>
            <div style={{textAlign: 'right', margin: '5px 0'}}>
                {`${formatCurrency(payload[0].value)}`}
            </div>
        </div>);
    }
    return null;
};

const formatDataForTreeMap = (data) => {
    if (!data) return [];
    const preppedData = [];

    const sourceTypes = data.getSourceTypeMany;
    const sources = data.getInstantAmountsBySource;

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ea6aff', '#6b72ff'];

    sourceTypes.forEach(type => {
        if (!type.is_asset) return;

        let color = 'black';
        if (type.key === 'BANK ACCOUNT') color = COLORS[1];
        if (type.key === 'CURRENCY') color = COLORS[2];
        if (type.key === 'REAL ESTATE') color = COLORS[0];
        if (type.key === 'RECEIVABLE') color = COLORS[3];
        if (type.key === 'STOCK') color = COLORS[4];
        if (type.key === 'VEHICLE') color = COLORS[5];

        const baseObj = {
            name: type.label_plural, fontFamily: "Helvetica", children: []
        }
        sources.forEach(source => {
            if (source.source_type_key === type.key) {
                baseObj.children.push({
                    name: source.source_name,
                    stroke: 'black',
                    strokeWidth: '5px',
                    fill: color,
                    size: source.latest_amount,
                    label: source.source_name
                });
            }
        });
        preppedData.push(baseObj);
    });
    return preppedData;
}

const SourceTypesTreemap = ({instantAmounts}) => {
    const treeMapData = formatDataForTreeMap(instantAmounts)
    if (!instantAmounts) return <Skeleton className={'skeleton-loader'} animation="wave" variant="rectangular" height={'100%'} width={'100%'}/>;
    return (<ResponsiveContainer className={'animated fadeIn slower'}>
        <Treemap
            type={'flat'}
            data={treeMapData}
            dataKey="size"
            isAnimationActive={false}
        >
            <Tooltip content={<CustomTooltip/>}/>
        </Treemap>
    </ResponsiveContainer>)
}

export default SourceTypesTreemap;
