import gql from 'graphql-tag';

// FRAGMENTS

export const monthlyReportFragment = gql`
    fragment MonthlyReport on MonthlyReport {
        _id,
        year_and_month,
        username,
        year,
        month,
        liquid_assets_total,
        frozen_assets_total,
        liabilities_total,
        net_worth,
        report_date
    }
`;

// QUERIES & MUTATIONS

export const getMonthlyReportMany = gql`
    query getMonthlyReportMany($sortObj: GenericSortInput) {
        getMonthlyReportMany(sortObj: $sortObj) { 
            ...MonthlyReport
        }
    }
    ${monthlyReportFragment}
`;

export const getInstantAmounts = gql`
    query {
        getInstantAmountsBySourceType { 
            source_type_name,
            current_value
        },
        getInstantAmountsBySource { 
            source_key,
            source_type_key,
            latest_amount,
            last_updated,
            source_name,
            source_type_name,
            is_asset,
            is_liquid
        },
        getSourceTypeMany {
            key,
            is_asset,
            is_liquid,
            label_singular,
            label_plural
        }
    }
`;
