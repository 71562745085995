import * as React from 'react';
import moment from 'moment';
import {CardContent, Typography} from '@mui/material';
import {formatCurrency} from '../../utils/textUtils';

const CardMonthlyReport = (props) => {
    return (<div className={'card-bg primary-font'} style={{textAlign: 'center', cursor: 'pointer'}}>
        <CardContent>
            <Typography sx={{mb: 1.5}} style={{fontWeight: '700', color: '#5c5c5c'}}>
                {moment(props.data.year_and_month).format('MMMM YYYY')}
            </Typography>
            <Typography variant="header">
          <span style={{fontWeight: '800', fontSize: '2rem'}}>
            {formatCurrency(props.data.net_worth).substring(0, formatCurrency(props.data.net_worth).length - 2)}
              <sup style={{
                  fontWeight: '800', fontSize: '1rem'
              }}>{formatCurrency(props.data.net_worth).substring(formatCurrency(props.data.net_worth).length - 2, formatCurrency(props.data.net_worth).length)}</sup>
          </span>
                <br/><br/>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Liquid Assets</div>
                    {formatCurrency(props.data.liquid_assets_total)}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Frozen Assets</div>
                    {formatCurrency(props.data.frozen_assets_total)}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Liabilities</div>
                    {formatCurrency(props.data.liabilities_total)}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Gain/Loss</div>
                    <div style={{color: props.data.change >= 0 ? 'gray' : '#FF8042'}}>
                        {formatCurrency(props.data.change)}
                    </div>
                </div>
            </Typography>
        </CardContent>
    </div>);
}

export default CardMonthlyReport;
