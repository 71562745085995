import gql from 'graphql-tag';

// FRAGMENTS

export const entryFragment = gql`
    fragment Entry on Entry {
        _id,
        username,
        source_key {
            source_name
        },
        entry_date,
        amount,
        comments
    }
`;

// QUERIES & MUTATIONS

export const getEntryMany = gql`
    query getEntryMany($findObj: FindEntryInput, $sortObj: GenericSortInput, $skip: Int, $limit: Int) {
        getEntryMany(findObj: $findObj, sortObj: $sortObj, skip: $skip, limit: $limit) { 
            ...Entry
        }
    }
    ${entryFragment}
`;

export const createEntry = gql`
    mutation createEntry($input: CreateEntryInput) {
        createEntry(input: $input) { 
            ...Entry
        }
    }
    ${entryFragment}
`;

export const updateEntry = gql`
    mutation updateEntry($input: UpdateEntryInput) {
        updateEntry(input: $input) { 
            nModified
        }
    }
`;

export const deleteEntry = gql`
    mutation deleteEntry($_id: ID!) {
        deleteEntry(_id: $_id) { 
            nModified
        }
    }
`;