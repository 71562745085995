import * as React from 'react';
import {CardContent, Typography} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const CardMonthlyReportSkeleton = (props) => {
    return (<div className='card-bg primary-font' style={{textAlign: 'center', cursor: 'pointer'}}>
        <CardContent>
            <Typography sx={{mb: 1.5}}
                        style={{fontWeight: '700', color: '#5c5c5c', display: 'flex', justifyContent: 'center'}}>
                <Skeleton className={'skeleton-loader'} style={{width: '150px'}}/>
            </Typography>
            <Typography variant="header">
          <span style={{
              fontWeight: '800', fontSize: '2rem', display: 'flex', justifyContent: 'center', marginBottom: '10px'
          }}>
            <Skeleton className={'skeleton-loader'} style={{width: '200px', height: '4rem'}}/>
          </span>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Liquid Assets</div>
                    <Skeleton className={'skeleton-loader'} style={{width: '100px'}}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Frozen Assets</div>
                    <Skeleton className={'skeleton-loader'} style={{width: '80px'}}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Liabilities</div>
                    <Skeleton className={'skeleton-loader'} style={{width: '130px'}}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <div style={{fontWeight: '800'}}>Gain/Loss</div>
                    <div style={{color: 'black'}}>
                        <Skeleton className={'skeleton-loader'} style={{width: '120px'}}/>
                    </div>
                </div>
            </Typography>
        </CardContent>
    </div>);
}

export default CardMonthlyReportSkeleton;
