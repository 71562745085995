import client from './graphql/_client';
import {
    archiveSource as archiveSourceMutation,
    createSource as createSourceMutation,
    getSourceMany as getSourceManyQuery,
    updateSource as updateSourceMutation
} from './graphql/source';
import {simulateDelay} from '../utils/requestUtils';

export const getSourceMany = async (sourceTypeKey) => {
    await simulateDelay();
    const {data} = await client.query({
        query: getSourceManyQuery, variables: {
            findObj: {
                source_type_key: sourceTypeKey
            }, // sortObj: {
            //     column: 'last_entry_amount',
            //     direction: -1
            // }
            // sortObj: {
            //     column: sortColumn,
            //     direction: sortDirection
            // },
            // skip: page * pageSize,
            // limit: pageSize
        }, fetchPolicy: 'no-cache'
    });
    return data.getSourceMany;
}

export const submitCreateSource = async (input) => {
    await simulateDelay();
    const {data: {source}} = await client.mutate({
        mutation: createSourceMutation, variables: {input}
    });
    return source;
}

export const submitUpdateSource = async (input) => {
    await simulateDelay();
    const {data: {source}} = await client.mutate({
        mutation: updateSourceMutation, variables: {input}
    });
    return source;
}

export const submitArchiveSource = async (input) => {
    await simulateDelay();
    const {data: {source}} = await client.mutate({
        mutation: archiveSourceMutation, variables: {input}
    });
    return source;
}
