import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const CardEntrySkeleton = (props) => {
    return (<Card className={'card-bg'} elevation={0} style={{minHeight: '230px'}}>
        <CardContent>
            <Typography sx={{mb: 1.5}} style={{fontWeight: '700'}}>
                <Skeleton className={'skeleton-loader'} style={{width: '150px'}}/>
            </Typography>
            <Typography variant="header">
          <span style={{fontWeight: '800', fontSize: '2rem'}}>
            <Skeleton className={'skeleton-loader'} style={{width: '100px', height: '4rem'}}/>
          </span>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                    <Skeleton className={'skeleton-loader'} style={{width: '200px'}}/>
                </div>
            </Typography>
            <div style={{display: 'flex', width: '100%'}}>
                <Skeleton className={'skeleton-loader'} style={{width: '40px', marginRight: '10px', height: '2rem'}}/>
                <Skeleton className={'skeleton-loader'} style={{width: '40px', marginRight: '10px', height: '2rem'}}/>
            </div>
        </CardContent>
    </Card>);
}

export default CardEntrySkeleton;
