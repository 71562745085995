import React, {useContext, useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import {ApplicationContext} from '../context/ApplicationContext';
import {submitLogin} from '../services/login.service';
import {CircularProgress} from "@mui/material";

const demoUsername = 'jsmith';
const demoPassword = '12345678';

const LoginPage = () => {
    const AppContext = useContext(ApplicationContext);
    const [providedUsername, setUsername] = useState('');
    const [providedPassword, setPassword] = useState('');
    const [isWaitingOnServer, setIsWaitingOnServer] = useState(false);

    const submitForm = (username, password) => {
        setIsWaitingOnServer(true);
        submitLogin(username, password)
            .then((reply) => {
                window.location.replace("/instantAnalytics");
                setIsWaitingOnServer(false);
            })
            .catch((err) => {
                console.log(err);
                AppContext.handleError('Failed login.');
                setIsWaitingOnServer(false);
            });
    };

    const inputStyle = {
        width: '100%', filter: 'invert(0.7)'
    }

    const buttonStyle = {
        margin: '5px', width: '150px', filter: 'invert(0.7)'
    }

    if (isWaitingOnServer) {
        return (
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh'
            }}>
                <Button className='primary-font' variant='ghost' style={buttonStyle}>
                    Logging in...
                </Button>
                <br/>
                <CircularProgress style={{color: 'gray'}}/>
            </div>
        )
    }

    return (<div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh'
        }}>
            <main className='primary-font' style={{width: '300px', textAlign: 'center'}}>
                <h2 style={{
                    fontFamily: 'Cookie', textAlign: 'center', fontSize: '40px', margin: '0px'
                }}>
                    Verdantime
                </h2>
                <br/>
                <TextField onInput={e => setUsername(e?.target?.value)} label="Username" variant="filled"
                           style={inputStyle}/>
                <br/><br/>
                <TextField onInput={e => setPassword(e?.target?.value)} label="Password" variant="filled"
                           type="password"
                           style={inputStyle}/>
                <br/><br/>
                <div>
                    <Button className='primary-font' onClick={()=>submitForm(providedUsername, providedPassword)} disabled={isWaitingOnServer} variant='outlined' style={buttonStyle}>
                        Login
                    </Button>
                </div>
                <br/><br/><br/>
                <div>or</div>
                <div>
                    <Button className='primary-font' onClick={()=>submitForm(demoUsername, demoPassword)} disabled={isWaitingOnServer} variant='ghost' style={buttonStyle}>
                        Try a Demo
                    </Button>
                </div>
            </main>
        </div>);

}

export default LoginPage;
