import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {ApplicationContext} from '../context/ApplicationContext';
import {Grid} from '@material-ui/core';
import {Skeleton} from '@mui/material';
import CardMonthlyReport from '../components/DataCards/CardMonthlyReport';
import CardMonthlyReportSkeleton from '../components/DataCards/CardMonthlyReportSkeleton';
import ComposedChart from '../components/Charts/MonthlyReportsTimeline';
import {getMonthlyReportMany} from '../services/monthlyReport.service';

const MonthlyReportsPage = () => {
    const AppContext = useContext(ApplicationContext);
    const [monthlyReports, setMonthlyReports] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [isAwaitingServer, setIsAwaitingServer] = useState(false);

    useEffect(async () => {
        setIsAwaitingServer(true);
        window.scrollTo(0, 0);
        try {
            const loadedMonthlyReports = await getMonthlyReportMany({column: 'year_and_month', direction: -1});
            const monthlyReportsWithChange = calculateMonthlyGainLoss(loadedMonthlyReports);
            setMonthlyReports(monthlyReportsWithChange);
            setLastUpdated(loadedMonthlyReports && loadedMonthlyReports[0] && loadedMonthlyReports[0].report_date ? loadedMonthlyReports[0].report_date : null)
        } catch (e) {
            AppContext.handleError('Unable to load monthly reports.');
        }
        setIsAwaitingServer(false);
    }, []);

    const calculateMonthlyGainLoss = (monthlyReports) => {
        monthlyReports = JSON.parse(JSON.stringify(monthlyReports)); // In order to make the GQL object extensible
        for (let i = 0; i < monthlyReports.length; i++) {
            try {
                monthlyReports[i].change = monthlyReports[i].net_worth - monthlyReports[i + 1].net_worth;
            } catch {
                monthlyReports[i].change = 0;
            }
        }
        return monthlyReports;
    }

    const generateMonthlyReportCards = () => {
        let monthlyReportCards = [];
        if (isAwaitingServer) {
            for (let i = 0; i < 12; i++) {
                monthlyReportCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <CardMonthlyReportSkeleton/>
                </Grid>);
            }
        } else {
            for (let i = 0; i < monthlyReports?.length; i++) {
                monthlyReportCards.push(<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={monthlyReports[i]._id}>
                    <CardMonthlyReport data={monthlyReports[i]}/>
                </Grid>);
            }
        }
        return monthlyReportCards;
    }

    return (<div className='animated fadeIn'>
        <section>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={'primary-font'} style={{display: 'flex', justifyContent: 'right'}}>
                        {isAwaitingServer ? <Skeleton className='skeleton-loader' style={{width: '35%'}}/> :
                            <span>Last updated on {moment(lastUpdated).format('LLL')}</span>}
                    </div>
                    <br/>
                </Grid>
                <Grid item xs={12} md={8} style={{minHeight: '240px'}}>
                    <div className={'card-bg'} style={{height: '100%', padding: '1rem'}}>
                        <ComposedChart chartData={monthlyReports}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={'card-bg'} style={{height: '100%', padding: '2rem'}}>
                            <span className='large-label primary-font'>View your wealth by month.</span>
                        <br/>
                        <p className='small-label primary-font'>Compare your financial standing
                            from month to month by viewing historical records of your assets, liabilities, and
                            equity.</p>
                    </div>
                </Grid>
            </Grid>
        </section>
        <br/>
        <Grid container spacing={1}>
            {generateMonthlyReportCards()}
        </Grid>
    </div>);

};

export default MonthlyReportsPage;
