import config from '../config';
import {simulateDelay} from '../utils/requestUtils';

export async function submitLogin(username, password) {
    await simulateDelay();
    const response = await fetch(`${config.apiUrl}/login`, {
        method: 'POST', headers: {
            'content-type': 'application/json'
        }, body: JSON.stringify({username, password})
    });
    if (response.ok) {
        const reply = await response.json();
        document.cookie = `jsonWebToken=${reply.token}`;
    } else {
        throw new Error('Failed login.');
    }
}

export async function submitLogout() {
    await simulateDelay();
    try {
        document.cookie = `jsonWebToken=${''}`;
        location.replace("/");
        setTimeout(() => document.location.reload(), 500); // Wipe out GQL cache
    } catch (err) {
        throw new Error('Failed logout.');
    }
}
