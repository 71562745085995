import cookie from 'cookie';
import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from '@apollo/client';
import {onError} from "@apollo/client/link/error";
import config from '../../config';

const httpUrl = `${config.apiUrl}/graphql`;

const httpLink = ApolloLink.from([
    new ApolloLink((operation, forward) => {
        const jsonWebToken = cookie.parse(document.cookie)?.jsonWebToken;
        if (jsonWebToken) operation.setContext({
            headers: {
                'authorization': `Bearer ${jsonWebToken}`
            }
        });
        return forward(operation);
    }),
    new HttpLink({uri: httpUrl})
]);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (message.toLowerCase().includes("not authenticated")) {
                location.replace("/loggedOut");
            }
        });
    }
    if (networkError) {
        const parsedNetworkError = JSON.stringify(networkError)
        if (parsedNetworkError.toLowerCase().includes("401")) {
            location.replace("/loggedOut");
        }
    }
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {query: {
        // fetchPolicy: 'cache-first'
    }}
});

export default client;
